import { inject, Injectable } from '@angular/core';
import { DocumentData, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { TeamListFactory } from '@index/daos/utils/team-list-factory';
import {
  CreateTeamRequest,
  EventItem,
  EventJoiner,
  ExternalTeam,
  ExternalTeamRatingItem,
  RosterV2,
  Team,
  TeamRating,
  TeamRosterItem,
  TeamSession,
  TeamSessionStatus,
  UpdateTeamRequest,
  User,
} from '@index/interfaces';
import { TeamListFilter } from '@index/interfaces/team-list-filter';
import { TeamMapper } from '@index/mappers/team-mapper';
import { TeamModel } from '@index/models/team';
import { DBUtil } from '@index/utils/db-utils';
import { firestorePopulate } from '@index/utils/populaters';
import {
  GthEventItemModel,
  GthTeamModel,
  GthTeamPlayerModel,
  GthTeamRoleModel,
} from '@sentinels/models';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import firebase from 'firebase/compat/app';
import {
  combineLatest,
  filter,
  from,
  lastValueFrom,
  mergeAll,
  Observable,
  of,
  switchMap,
  toArray,
} from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  first,
  map,
  mergeMap,
  shareReplay,
  take,
} from 'rxjs/operators';

import { applyLocationMapsBounds, generateBoundsFromLatLng } from '../helpers/geobounds';
import { UnregisteredUserService } from './unregistered-user.service';

@Injectable({ providedIn: 'root' })
export class TeamsService extends FirestoreService<GthTeamModel> {
  private unregUserService = inject(UnregisteredUserService);
  private usersService = inject(UserService);

  readonly basePath = 'teams';

  private mapper = new TeamMapper();
  private teamListFactory = new TeamListFactory(this.firestore as any);

  async getRatings(id: string): Promise<ExternalTeamRatingItem[]> {
    const ref = await this.firestore
      .collection(`${this.basePath}/${id}/ratings`)
      .get()
      .pipe(first())
      .toPromise();

    if (ref.empty) {
      return [];
    }

    return ref.docs.map((d) => d.data()) as ExternalTeamRatingItem[];
  }

  async rate(userId: string, id: string, ratings: TeamRating[]) {
    if (!id || !userId) return Promise.resolve(false);

    const ref = await lastValueFrom(this.doc(id).get());

    if (!ref?.exists) {
      return false;
    }

    await this.deleteRatingsByUserId(id, userId);

    const ratingsRef = this.firestore.collection(`${this.basePath}/${id}/ratings`);

    const requests = [];
    for (let i = 0; i < ratings.length; i++) {
      const model = ratings[i];

      requests.push(
        ratingsRef.add({
          userId,
          rating: model.id,
        }),
      );
    }

    return Promise.allSettled(requests);
  }

  async deleteRatingsByUserId(id: string, userId: string) {
    const batch = this.firestore.firestore.batch();

    const qs = await lastValueFrom(
      this.firestore
        .collection(`${this.basePath}/${id}/ratings`, (ref) => {
          return ref.where('userId', '==', userId);
        })
        .get(),
    );

    qs.docs.forEach((doc) => batch.delete(doc.ref));

    try {
      await batch.commit();

      return true;
    } catch {
      return false;
    }
  }

  getUserTeamsQuery$(userId: string) {
    return this.firestore
      .collection<TeamRosterItem>('team_roster', (ref) => {
        return ref.where('userId', '==', userId);
      })
      .get();
  }

  getTeamPlayersQuery$(teamId: string) {
    return this.firestore
      .collection<TeamRosterItem>('team_roster', (ref) => {
        return ref.where('teamId', '==', teamId);
      })
      .valueChanges({ idField: 'id' }) as Observable<TeamRosterItem[]>;
  }

  getTeamByDomain$(domain: string) {
    return this.firestore
      .collection<Team>('teams', (ref) => {
        return ref.where('customDomain', '==', domain);
      })
      .get().pipe(
        map((snapshot) => {
          if (snapshot.empty) return undefined;
          const doc = snapshot.docs[0];
          const team = doc.data() as Team;
          return new GthTeamModel(doc.id, team);
        }),
      );
  }

  getTeamByTeamId$(teamId: string): Observable<GthTeamModel> {
    return this.getTeamPlayers$(teamId).pipe(
      map((roster) => ({ teamId, roster })),
      switchMap((teamWithRoster) => {
        return this.getTeam$(teamId).pipe(
          map((team) => {
            if (!team) return undefined;
            team.roster = teamWithRoster.roster;
            return new GthTeamModel(team.id, team);
          }),
        );
      }),
    );
  }

  getExternalTeamsByUserId$(creatorId: string): Observable<ExternalTeam[]> {
    const teams$ = this.firestore
      .collection(DBUtil.ExternalTeams, (ref) => ref.where('creator', '==', creatorId))
      .valueChanges({ idField: 'id' }) as unknown as Observable<ExternalTeam[]>;

    return teams$;
  }

  getTeamsByLocation$(lat: number, lng: number, bounds?: google.maps.LatLngBoundsLiteral) {
    interface TeamWithId extends Team {
      id: string;
    }
    bounds = bounds || generateBoundsFromLatLng(lat, lng, 2000);
    const teams$ = from(
      this.firestore
        .collection(DBUtil.Team, (ref) => applyLocationMapsBounds(ref, bounds))
        .get(),
    ).pipe(
      map((snapshot) =>
        snapshot.docs.map((doc) => {
          const teamData = doc.data() as TeamWithId;
          return { ...teamData, id: doc.id };
        }),
      ),
      shareReplay(1),
    );
    return teams$.pipe(
      take(1),
      /** Convert EventItem[] to GthEventItemModel[] */
      map((teams) => teams.map((team) => new GthTeamModel(team.id, team))),
    );
  }

  getTeamPlayers$(teamId: string): Observable<GthTeamPlayerModel[]> {
    return this.getTeamPlayersQuery$(teamId).pipe(
      map((roster) => {
        return roster.map((roster) => {
          return { ...roster, id: roster.id };
        });
      }),
      switchMap((rosterItems) => {
        if (!rosterItems?.length) return of([]);
        const requests$ = [];

        rosterItems.forEach((rosterItem) => {
          if (!rosterItem.userId) {
            const noIdRequest$ = of(
              new GthTeamPlayerModel('', rosterItem.id, {
                player: {
                  displayName: rosterItem.id,
                  email: rosterItem.id,
                  uid: '',
                  createdAt: firebase.firestore.Timestamp.now(),
                  updatedAt: firebase.firestore.Timestamp.now(),
                },
                role: rosterItem.role,
                pendingRole: rosterItem.pendingRole,
              }),
            );
            requests$.push(noIdRequest$);
          } else if (rosterItem.unregisteredUser) {
            const unregisteredUserRequest$ = this.unregUserService
              .getUnregisteredUser$(rosterItem.userId)
              .pipe(
                map((user) => {
                  if (!user) return undefined;
                  return new GthTeamPlayerModel(user.uid, rosterItem.id, {
                    role: rosterItem.role,
                    pendingRole: rosterItem.pendingRole,
                    player: user,
                  });
                }),
              );
            requests$.push(unregisteredUserRequest$);
          } else {
            const standardRequest$ = this.usersService.getUser$(rosterItem.userId).pipe(
              map((user) => {
                if (!user) return undefined;
                return new GthTeamPlayerModel(user.uid, rosterItem.id, {
                  role: rosterItem.role,
                  player: user,
                  pendingRole: rosterItem.pendingRole,
                });
              }),
              catchError(() => {
                return of(undefined);
              }),
            );
            requests$.push(standardRequest$);
          }
        });
        return combineLatest(requests$);
      }),
      map((players) => {
        const participants: GthTeamPlayerModel[] = [];
        players.forEach((p) => {
          if (p) {
            const index = participants.findIndex((x) => x.player.uid === p.player.uid);
            if (index === -1) {
              participants.push(p);
            }
          }
        });
        return participants;
      }),
    );
  }

  removeTeamPlayer$(teamId: string, userId: string) {
    return this.firestore
      .collection<TeamRosterItem>('team_roster', (ref) => {
        return ref.where('teamId', '==', teamId).where('userId', '==', userId);
      })
      .get()
      .pipe(
        filter((query) => !query.empty),
        map((query) => query.docs[0].id),
        switchMap((rosterId) => {
          return this.firestore
            .collection<TeamRosterItem>('team_roster')
            .doc(rosterId)
            .delete();
        }),
      );
  }

  getTeamEventsForSession$(
    teamId: string,
    sessionId?: string,
  ): Observable<GthEventItemModel[]> {
    const docs = this.firestore
      .collection<EventItem>(DBUtil.EventItem, (ref) => {
        if (sessionId) {
          return ref.where('hostingTeam', '==', teamId).where('sessionId', '==', sessionId);
        }
        return ref.where('hostingTeam', '==', teamId);
      })
      .get();
    return docs.pipe(
      map((docs) => {
        if (docs.empty) return [];
        return docs.docs
          .map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          })
          .map((eventItem) => new GthEventItemModel(eventItem.id, eventItem));
      }),
      mergeAll(),
      mergeMap((event) => this.setEventJoiners$(event)),
      toArray(),
    );
  }

  /**
   * Gets list of all team roles
   * @return {Observable<GthTeamRoleModel[] | undefined>} List of all team roles
   */
  getTeamRoles$() {
    const collection = this.firestore.collection<any>(DBUtil.TeamRoles);
    return collection.snapshotChanges().pipe(
      map((items) => {
        return items
          .map((item) => {
            const doc = item.payload.doc;
            const data = {
              uid: doc.id,
              ...doc.data(),
            };
            return new GthTeamRoleModel(doc.id, data);
          })
          .filter((r) => !r.label.startsWith('Pending'));
      }),
    );
  }

  getTeamsByUserId$(userId: string) {
    return this.getUserTeamsQuery$(userId).pipe(
      filter((userTeamsQuery) => !userTeamsQuery.empty),
      map((userTeamsQuery) => {
        return userTeamsQuery.docs.map((docSnap) => {
          return <string>docSnap.get('teamId');
        });
      }),
      switchMap((teamIds) => {
        if (!teamIds?.length) return of([]);
        return combineLatest(
          teamIds.map((teamId) =>
            this.getTeamPlayers$(teamId).pipe(map((roster) => ({ teamId, roster }))),
          ),
        );
      }),
      switchMap((teamsWithRosters) => {
        if (!teamsWithRosters?.length) return of([]);
        const teamIds = teamsWithRosters.map((t) => t.teamId);
        return this.getTeams$(teamIds).pipe(
          map((teams) =>
            teams
              .filter((t) => t.name)
              .map((team) => {
                const teamWithRoster = teamsWithRosters.find((t) => t.teamId === team.id);
                if (teamWithRoster) {
                  team.roster = teamWithRoster.roster;
                }
                return new GthTeamModel(team.id, team);
              }),
          ),
        );
      }),
      defaultIfEmpty([]),
    );
  }

  async createTeam(model: TeamModel<User> | CreateTeamRequest) {
    try {
      const roster = model.roster;

      model.roster = [];

      const collectionRef = this.collection;
      const teamId = this.firestore.createId();
      const doc = collectionRef.doc(teamId);

      const modelRef = await lastValueFrom(doc.get());

      if (!modelRef) throw new Error('Error reading model ref');

      model['ref'] = modelRef.ref;

      const teamMap = this.mapper.toMap(model as TeamModel<User>);

      delete teamMap['id'];

      const teamDoc = Object.assign(
        {
          created: firebase.firestore.Timestamp.now(),
        },
        teamMap,
      );

      // add a default "active" session because we always need an active session
      const sessionId = this.firestore.createId();
      const sessionRef = collectionRef
        .doc(teamId)
        .collection(DBUtil.TeamSessions)
        .doc(sessionId);
      const defaultSession: TeamSession = {
        id: sessionId,
        label: 'Default Session',
        status: TeamSessionStatus.ACTIVE,
        startDate: new Date(),
        archived: false,
      };

      /** Create a write batch */
      const batch = this.firestore.firestore.batch();

      /** Create Team Document */
      batch.set(doc.ref, teamDoc, { merge: true });
      batch.set(sessionRef.ref, defaultSession, { merge: true });

      if (roster && roster.length) {
        for (let i = 0; i < roster.length; i++) {
          const playerUID = roster[i].player.uid;

          const rosterDoc = await doc.collection(DBUtil.Roster).doc(playerUID);

          const rosterItem = {
            ...(roster[i] as any).model,
          };

          rosterItem.player = playerUID;

          /** Create Team Player Document */
          batch.set(rosterDoc.ref, rosterItem);
        }
      }

      /** Commit batch operations */
      return await batch
        .commit()
        /** Return Team Document ID */
        .then(() => {
          const teamId = doc.ref.id;

          return teamId;
        });
    } catch (e) {
      throw e;
    }
  }

  async updateTeam(teamModel: TeamModel<string> | UpdateTeamRequest) {
    const teamDocRef = this.doc(teamModel.id);

    const teamRef = await lastValueFrom(teamDocRef.get());

    if (!teamRef) return false;

    teamModel['ref'] = teamRef.ref;

    const roster = teamModel.roster;

    teamModel.roster = [];

    const teamMap = this.mapper.toMap(teamModel as TeamModel<string>);

    const batch = this.firestore.firestore.batch();

    if (roster && roster.length) {
      for (const player of roster) {
        const playerUID = player.player;
        if (playerUID) {
          const rosterDoc = await teamDocRef.collection(DBUtil.Roster).doc(playerUID);
          const rosterSnap = await rosterDoc.get().pipe(take(1)).toPromise();
          if (rosterSnap && rosterSnap.exists) {
            /** update player document */
            batch.update(rosterDoc.ref, player);
          } else {
            /** create player document */
            batch.set(rosterDoc.ref, player);
          }
        }
      }

      const rosterRef = teamDocRef.collection(DBUtil.Roster);

      const rosterSnap = await lastValueFrom(rosterRef.get().pipe(take(1)));

      if (rosterSnap) {
        for (let i = 0; i < rosterSnap.docs.length; i++) {
          const rosterDocSnap = rosterSnap.docs[i];

          /** if player not in roster, remove player document */
          if (roster.some((p) => p.player === rosterDocSnap.id)) continue;

          batch.delete(rosterDocSnap.ref);
        }
      }
    }

    /** Update Team Document */
    const teamDoc = Object.assign(
      {
        roster: [],
        updated: firebase.firestore.Timestamp.now(),
      },
      teamMap,
    );
    batch.set(teamDocRef.ref, teamDoc, { merge: true });

    /** Commit batch operations */
    return batch.commit().then(() => true);
  }

  async read(request: { id: string }) {
    const snap = (await lastValueFrom(
      this.doc(request.id).get(),
    )) as DocumentSnapshot<DocumentData>;

    if (!snap.exists) return null;

    const teamModels = await this.generateTeamModelsFromSnapShots([snap]);

    return teamModels[0];
  }

  async deleteTeam(request: { id: string }) {
    const docRef = this.doc(request.id!);

    const rosterRef = docRef.collection('roster');

    /** Create a write batch */
    const batch = this.firestore.firestore.batch();

    /** Delete Team Document */
    batch.delete(docRef.ref);

    const rosterSnap = await rosterRef.get().pipe(take(1)).toPromise();

    if (!rosterSnap) return false;

    for (const snapshot of rosterSnap.docs) {
      /** Delete Team Player Document */
      batch.delete(snapshot.ref);
    }

    /** Commit batch operations */
    return await batch
      .commit()
      .then(() => true)
      .catch(() => false);
  }

  async list(filter: TeamListFilter) {
    const teams = await this.teamListFactory.get(filter);

    return this.generateTeamModelsFromSnapShots(teams);
  }

  private setEventJoiners$(event: GthEventItemModel) {
    if (!event) return of(event);

    return this.firestore
      .collection(DBUtil.EventItem)
      .doc(event.id)
      .collection(DBUtil.EventJoiner)
      .get()
      .pipe(
        map((ref) => {
          if (ref.empty) return event;
          const joiners = ref.docs.map((j) => j.data() as EventJoiner);
          event.participants = joiners;
          return event;
        }),
      );
  }

  private getTeams$(teamIds: string[]) {
    if (!teamIds?.length) return of([]);

    const teamsArr = teamIds.map((id) => this.getTeam$(id));
    return combineLatest(teamsArr);
  }

  private getTeam$(teamId: string) {
    return this.doc$<Team>(teamId).pipe();
  }

  private async generateTeamModelsFromSnapShots(
    snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>[],
  ) {
    const teamModelArray: TeamModel<User>[] = [];

    for (const team of snapshot) {
      if (team) {
        const teamModel: TeamModel<User | string> = new TeamMapper().fromSnapshot(team);

        if (teamModel) {
          const rosterRef = await team.ref.collection(DBUtil.Roster).get();

          (teamModel as TeamModel<User>).roster = await this.populateUsersInRosterFromIds(
            rosterRef.docs,
          );
          teamModelArray.push(teamModel as TeamModel<User>);
        }
      }
    }

    return teamModelArray;
  }

  private async populateUsersInRosterFromIds(
    docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[],
  ) {
    const rosterArray: RosterV2<User>[] = [];

    for (const roster of docs) {
      const userItem = await firestorePopulate(
        this.firestore,
        DBUtil.User,
        roster.data()['player'],
      );

      if (userItem) {
        const popUser = userItem.data();

        rosterArray.push({
          role: roster.data()['role'],
          player: popUser as User,
        });
      }
    }

    return rosterArray;
  }
}
